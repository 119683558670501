import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/login.vue'


Vue.use(VueRouter)
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter:(to,form,next) => {
      if(null != localStorage.getItem('token')){
        next()
      }else{
        next('/login')
      }
    },
    children:[
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/order.vue'),
        meta: { title: '订单信息' }
      },
      {
        path: '/recycleBin',
        name: 'recycleBin',
        component: () => import('../views/recycleBin/recycleBin.vue'),
        meta: { title: '订单回收站' }
      },
      {
        path: '/bill',
        name: 'bill',
        component: () => import('../views/bill/bill.vue'),
        meta: { title: '账单查询' }
      },
      {
        path: '/cost',
        name: 'cost',
        component: () => import('../views/cost/cost.vue'),
        meta: { title: '费用管理' }
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/setting/setting.vue'),
        meta: { title: '个人信息' }
      },
      {
        path: '/settingCompany',
        name: 'settingCompany',
        component: () => import('../views/settingCompany/settingCompany.vue'),
        meta: { title: '公司信息' }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/user.vue'),
        meta: { title: '员工管理' }
      },
      {
        path: '/customer',
        name: 'customer',
        component: () => import('../views/customer/customer.vue'),
        meta: { title: '客户管理' }
      },
      {
        path: '/Partner',
        name: 'Partner',
        component: () => import('../views/Partner/Partner.vue'),
        meta: { title: '合作方管理' }
      },
      {
        path: '/shipManage',
        name: 'shipManage',
        component: () => import('../views/shipManage/shipManage.vue'),
        meta: { title: '船公司管理' }
      },
      {
        path: '/address',
        name: 'address',
        component: () => import('../views/address/address.vue'),
        meta: { title: '地址管理' }
      },
      {
        path: '/license',
        name: 'license',
        component: () => import('../views/license/license.vue'),
        meta: { title: '车牌号管理' }
      },
      {
        path: '/reconciliation',
        name: 'reconciliation',
        component: () => import('../views/reconciliation/reconciliation.vue'),
        meta: { title: '对账查询' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
