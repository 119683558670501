// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);
 
export default new Vuex.Store({
  state: {
    tabs: []
  },
  mutations: {
    addTab(state, tab) {
      state.tabs.push(tab);
    },
    removeTab(state, tabName) {
      state.tabs = state.tabs.filter(tab => tab.name !== tabName);
    }
  }
});